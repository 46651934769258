<template>
  <div class="container">
    <div class="top">
      <div><img src="@/assets/image/rith.png" alt="" /></div>
      <div class="Description">
        <img src="@/assets/image/Frame.png" alt="" />
      </div>
      <div class="Download-btn">
        <van-button
          @click="getDownloadApp"
          :icon="DownloadIcon"
          round
          color="linear-gradient(95deg, #21F0CB 0%, #8BDD03 100%)"
        >
          Download e-Rith App
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/utils/mixins";
export default {
  mixins: [Mixins],
  data() {
    return {
      DownloadIcon: require("@/assets/image/interactive-button.png")
    };
  },
  methods: {
    getDownloadApp() {
      var u = navigator.userAgent;
      var isAndroid =
      /Android/.test(u) //android终端
      var isiOS =
      /iPhone|iPad|iPod/.test(u) && /AppleWebKit/.test(u) //ios终端
      console.log(u);
        if(isAndroid){
          window.location.href='https://play.google.com/store/apps/details?id=com.rith.shop'
        }else if(isiOS){
          window.location.href='https://apps.apple.com/hk/app/e-rith/id6503116009'

        } 
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
  .Description {
    padding: 56px 53px;
  }
  .Download-btn {
    padding: 0 34px;
  }
}
:deep(.van-icon__image) {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.Download-btn .van-button {
  width: 100% !important;
}
</style>
